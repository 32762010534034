import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Johns Paul | Developer, Student, Creator', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hey, I'm",
  name: 'Johns',
  subtitle: 'a',
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://drive.google.com/file/d/12P5Q3gYwBJdbZ48_aJd1xiYmEi4My65G/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'zaio.webp',
    title: 'Zaio',
    info: 'Led and implemented the website B2C and B2B landing pages from concept through the deployment of Zaio - an online e-learning coding platform.',
    info2: '',
    url: 'https://www.zaio.io/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'brightmind.webp',
    title: 'Brightmind',
    info: 'Led and implemented the website landing page in ReactJS from concept through the deployment of Brightmind - an online learning app that provides an engaging & accessible educational experience for K-12 students in Africa.',
    info2: '',
    url: 'https://brightmind.guru/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'frndscard.webp',
    title: 'FRNDSinSPACE',
    info: 'Developed a web-app to track the ISS and who is currently in it using REST APIs, vanilla JS, HTML & CSS',
    info2: '1st place at UCT developer society hackathon - sponsored by Allan Gray',
    url: 'https://frndsinspace.netlify.app/',
    repo: 'https://github.com/johnsvpaul/FRNDSinSPACE', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: "I'm always open to new opportunities! Whether you want to work with me or just want to say hi, my inbox is always open. I'll try my best to get back to you!",
  btn: 'Say Hello',
  email: 'johnspaul99@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/johnsvpaul',
    },

    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/johnsvpaul',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/johnsvpaul',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
